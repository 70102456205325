<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form action="#" @submit.prevent="showModal({ type: 'submit' })">
          <div class="form-body">
            <!-- <h3 class="card-title">Tindak Lanjut Surat</h3>
            <hr /> -->

            <div class="form-body">
              <h3 class="box-title">Pemesanan Ruangan</h3>
              <hr class="m-t-0 m-b-40" />

              <div class="row">
                <div class="col-md-8">
                  <Input 
                    v-model="payload.nama_kegiatan" 
                    name="nama_kegiatan"
                    type="text"
                    label="Nama Kegiatan"
                   />
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <Input
                    v-model="payload.tanggal_kegiatan"
                    name="tanggal_kegiatan"
                    type="date"
                    label="Tanggal Kegiatan"
                   />
                </div>
              </div>

              <!-- <div class="row">
                <div class="col-md-8">
                  <div class="form-group row">
                    <label
                      :class="[
                        'control-label',
                        { 'text-right': !isMobile },
                        'col-md-3',
                      ]"
                      >Waktu Mulai
                    </label>
                    <div :class="['col-md-9']">
                      <Input 
                        v-model="payload.waktu_mulai"
                        name="waktu_mulai"
                        type="time"
                      />
                      <select
                        class="form-control custom-select"
                        v-model="payload.waktu_kegiatan"
                      >
                        <option value>Pilih Waktu Kegiatan</option>
                        <option value="8 - 12">8 - 12</option>
                        <option value="13 - 17">13 - 17</option>
                        <option value="8 - 17">8 - 17</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="row">
                <div class="col-md-8">
                  <Input
                    v-model="payload.waktu_mulai"
                    name="waktu_mulai"
                    type="time"
                    label="Waktu Mulai"
                   />
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <Input
                    v-model="payload.waktu_akhir"
                    name="waktu_selesai"
                    type="time"
                    label="Waktu Selesai"
                   />
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <Input 
                    v-model="payload.jumlah_peserta"
                    name="jumlah_peserta"
                    type="text"
                    label="Jumlah Peserta"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <Input 
                    v-model="payload.nama_penanggungjawab"
                    name="nama_penanggungjawab"
                    type="text"
                    label="Nama Penanggungjawab"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <Input 
                    v-model="payload.kontak_person"
                    name="kontak_person"
                    type="text"
                    label="Kontak Person"
                  />
                </div>
              </div>

              <!-- <div class="row"> -->
                <h3 class="box-title">Catatan / Keterangan</h3>
                <hr class="m-t-0 m-b-40" />

                <div class="row">
                  <div class="col-md-12">
                    <wysiwyg v-model="myHTML" />
                  </div>
                </div>
                <br />
              <!-- </div> -->
              

            </div>
          </div>

          <hr class="m-t-40" />

          <div class="form-actions">
            <button
              type="button"
              @click="showModal({ type: 'submit' })"
              class="btn btn-success"
              title="Simpan"
            >
              Submit</button
            >&nbsp;
            <button
              type="button"
              @click="showModal({ type: 'cancel' })"
              class="btn btn-inverse"
              title="Batal"
            >
              Cancel
            </button>
          </div>
        </form>
        <ModalDefault
          v-show="isVisible"
          :title="modalTitle"
          :content="modalContent"
          :action="ActionChangeable"
          :confirmation="isConfirmation"
          @close="onClose"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";

export default {
  components: {
    Input,
    RotateSquare5,
    Upload,
    Multiselect,
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.meetingPesan;
    },
    isinvalidOpd() {
      return this.isTouchedOpd && this.payload.valueOpd.length === 0;
    },
    optionJenisDokumen() {
      const jenisdokumen = JSON.parse(localStorage.getItem("refUnit"));
      const jenisdokumenDropdown = [];
      if (jenisdokumen) {
        jenisdokumen.forEach((value) => {
          jenisdokumenDropdown.push({ id: value.id, text: value.name });
        });
      }

      return jenisdokumenDropdown;
    },
    optionSifatDokumen() {
      const sifatdokumen = JSON.parse(localStorage.getItem("refUnit"));
      const sifatdokumenDropdown = [];
      if (sifatdokumen) {
        sifatdokumen.forEach((value) => {
          sifatdokumenDropdown.push({ id: value.id, text: value.name });
        });
      }

      return sifatdokumenDropdown;
    },
    isInvalidBahasa() {
      return this.isTouched && this.payload.valueBahasa.length === 0;
    },
    isInvalidJenisDokumen() {
      return (
        this.isTouchedJenisDokumen &&
        this.payload.valueJenisDokumen.length === 0
      );
    },
    isInvalidSifatDokumen() {
      return (
        this.isTouchedSifatDokumen &&
        this.payload.valueSifatDokumen.length === 0
      );
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  data() {
    return {
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable",
      ],
      payload: {
        nama_kegiatan: "",
        tanggal_kegiatan: "",
        waktu_mulai: "",
        waktu_akhir: "",
        jumlah_peserta: "",
        nama_penanggungjawab: "",
        kontak_person: "",
        myHTML: "",
      },
      myHTML: "",
      isDisabled: false,
      optionOpd: [],
      idLoading: false,
      isModalVisible: false,
      isVisible: false,
      modalTitle: "",
      modalContent: "",
      isConfirmation: false,
      ActionChangeable: null,
      disabledButton: false,
    };
  },
  async mounted() {
    this.unitFindOpd("");
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        if (this.editMode) {
          axios.get(`/meetingPesan/${this.$route.params.id}`).then((response) => {
            const state = {
              loaded: true,
            };
            this.$store.commit("meetingPesan/STATE", state);
            this.edit(response.data.data);
          });
        } else {
          this.$store.dispatch("meetingPesan/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    onClose() {
      this.isVisible = false;
    },
    showModal(option) {
      this.isVisible = true;
      console.log(option.type);
      // console.log(option);
      if (option.type == "submit") {
        // if (option.data == "undefined") {
          this.modalTitle = "Simpan Data?";
          this.modalContent = "<p>Apakah anda yakin ingin menyimpan pesanan?</p>";
          this.isConfirmation = true;
          this.ActionChangeable = this.submit;
        // } else if (option.data == 1) {
        //   this.modalTitle = "Kirim Data?";
        //   this.modalContent = "<p>Apakah anda yakin ingin mengirim pesanan?</p>";
        //   this.isConfirmation = true;
        //   this.ActionChangeable = this.submit;
        // }
      } else if (option.type == "cancel") {
        this.modalTitle = "Kembali?";
        this.modalContent = "<p>Apakah anda yakin ingin kembali?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      }
    },
    edit(data) {
      this.payload = {
        nama_kegiatan: data.nama_kegiatan,
        tanggal_kegiatan: data.tanggal_kegiatan,
        waktu_mulai: data.waktu_mulai,
        waktu_akhir: data.waktu_akhir,
        jumlah_peserta: data.jumlah_peserta,
        nama_penanggungjawab: data.nama_penanggungjawab,
        kontak_person: data.kontak_person,
        keterangan: data.keterangan,
      };
    },
    onTouchJenisDokumen() {
      this.isTouchedJenisDokumen = true;
    },
    onTouchSifatDokumen() {
      this.isTouchedSifatDokumen = true;
    },
    onChangeOpd(value) {
      this.payload.valueOpd = value;
    },
    onChangeBahasa(value) {
      this.payload.valueBahasa = value;
      if (value.indexOf("Reset me!") !== -1) this.payload.valueBahasa = [];
    },
    onChangeJenisDokumen(value) {
      this.payload.valueJenisDokumen = value;
      if (value.indexOf("Reset me!") !== -1)
        this.payload.valueJenisDokumen = [];
    },
    onChangeSifatDokumen(value) {
      this.payload.valueSifatDokumen = value;
      if (value.indexOf("Reset me!") !== -1)
        this.payload.valueSifatDokumen = [];
    },
    onSelect(option) {
      if (option === "Disable me!") this.isDisabled = true;
    },
    goBack() {
      this.$store.dispatch("meetingPesan/onCancel");
    },
    unitFindOpd(query) {
      this.isLoading = true;
      axios
        .get(`units/list?s=${query}`)
        .then((res) => {
          this.optionOpd = res.data.items;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.commit("auth/LOGOUT");
          }
        });
    },
    submit() {

      const payload = {
        nama_kegiatan: this.payload.nama_kegiatan,
        tanggal_kegiatan: this.payload.tanggal_kegiatan,
        waktu_mulai: this.payload.waktu_mulai,
        waktu_akhir: this.payload.waktu_akhir,
        jumlah_peserta: this.payload.jumlah_peserta,
        nama_penanggungjawab: this.payload.nama_penanggungjawab,
        kontak_person: this.payload.kontak_person,
        keterangan: this.myHTML,
      };

      console.log(this.payload.waktu_mulai);
      const data = JSON.stringify(payload);
      // this.$validator.validateAll().then((success) => {
        // if (success) {
          if (this.editMode) {
            this.$store.dispatch("meetingPesan/submitEdit", {
              data,
              id: this.$route.params.id,
            });
          } else {
            this.$store.dispatch("meetingPesan/submitAdd", data);
          }
        // }
      // });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

